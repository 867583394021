import axios from "axios"
import useJwt from '@src/auth/jwt/useJwt'
import { API_URL, AUTH, GENERAL, NULL, RD_SIGNUP_FINAL, BRAND_SIGNUP_FINAL } from '../../actionTypes'
const config = useJwt.jwtConfig
import io from "socket.io-client"
const ENDPOINT = process.env.REACT_APP_CHAT_ENDPOINT
const socket = io(ENDPOINT, { transport: ['websocket'] })

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const handleLogout = () => {
  const authorizationToken = getToken()
  const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
  socket.emit('disconnectuser', user, (error) => {
  })
  localStorage.removeItem(`${process.env.REACT_APP_SELECTED_BRAND_USER_FOR_ADMIN_CHAT}`)
  localStorage.removeItem(`${process.env.REACT_APP_SELECTED_RD_USER_FOR_ADMIN_CHAT}`)
  localStorage.removeItem(`${process.env.REACT_APP_USERDATA}`)
  localStorage.removeItem('selectedChatRd')
  localStorage.removeItem('selectedChatBrand')
  localStorage.removeItem('selectedChatUserForAdmin')
  localStorage.removeItem(config.storageTokenKeyName)
  localStorage.removeItem(config.storageRefreshTokenKeyName)
  localStorage.removeItem('handleLogout')
  return dispatch => {
    axios.get(`${API_URL}logout`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authorizationToken}`
      }
    }).then((res) => {
      dispatch({ type: AUTH.USER_LOGOUT, userLogout: res.data })
    }).catch(error => {
      console.log(error)
    })
  }
}

export const handleLogin = data => {
  return dispatch => {
    const formData = new FormData()
    formData.append("email", data.email)
    formData.append("password", data.password)
    formData.append("target", data.target)
    formData.append("re_captcha_token", data.re_captcha_token)
    // await axios.post(`http://127.0.0.1:8000login-submit`, formData, {
    axios.post(`${process.env.REACT_APP_BASEURL}login-submit`, formData, {
      headers: {
        Accept: "application/json"
      } 
    }).then(response => {
      const userData = {
        name: response.data.name,
        email: response.data.email,
        role: response.data.role,
        chat_id: response.data.chat_id,
        phone_number: response.data.phone_number,
        ability: [{ action: "manage", subject: response.data.role === 'sub_admin' ? 'admin' : response.data.role}],
        profile_image: response.data.profile_img,
        // subscriptionexp: true,
        subscriptionexp: response.data.subscription_exp,
        parent: response.data.parent_id,
        profile_approved: response.data.profile_approved,
        logo: response.data.logo,
        brand_name: response.data.brand_name,
        expire: response.data.expireDate,
        is_rd_plan: response.data.is_rd_plan,
        selected_rd_plan: response.data.selected_rd_plan,
        is_tour: response.data.tour,
        email_verified: response.data.email_verified,
        stripe_account: response.data.stripe_account,
        stripe_account_status: response.data.stripe_account_status,
        permissions: response.data.permissions
      }

      if (response.data.success) {
        localStorage.setItem(`${process.env.REACT_APP_USERDATA}`, JSON.stringify(userData))
        localStorage.setItem(`${process.env.REACT_APP_USERTOKEN}`, response.data.token)
        dispatch({
          type: 'LOGIN',
          data: response.data
        })
      } else {
        // localStorage.removeItem(`${process.env.REACT_APP_USERDATA}`)
        // localStorage.removeItem(`${process.env.REACT_APP_USERTOKEN}`)
        dispatch({
          type: 'LOGIN',
          data: response.data
        })
      }

    }).catch((error) => {
      // localStorage.removeItem(`${process.env.REACT_APP_USERDATA}`)
      // localStorage.removeItem(`${process.env.REACT_APP_USERTOKEN}`)
      dispatch({
        type: 'MANAGE_RD_GET_DATA',
        allData: "response.data.allData",
        manageRDsList: [1, 2, 3, 4],
        totalPages: "response.data.total"
      })
    })
  }
}

export const verifyEmail = (body) => {
  return dispatch => {
    axios.post(`${API_URL}verify-email`, {
      email: body.email
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      dispatch({ type: 'SIGNUP_DT_VERIFY_EMAIL', response: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const sendForgetPasswordEmail = ({ email, re_captcha_token }) => {
  return dispatch => {
    axios.post(`${API_URL}send-password-link`, {
      email,
      re_captcha_token
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      dispatch({ type: 'USER_FORGET_PASSWORD_EMAIL', forgetPasswordResponse: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const resetMyPassword = (params) => {
  return dispatch => {
    axios.post(`${API_URL}reset-password`, {
      token: params.token,
      new_password: params.new_password,
      confirm_password: params.confirm_password,
      re_captcha_token: params.re_captcha_token
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      dispatch({ type: 'USER_RESET_PASSWORD', resetPasswordResponse: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const getCitiesForBrand = () => {
  return dispatch => {
    axios.post(`${API_URL}cities`, {
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.CITIES_BY_STATES_ID, citiesByStateID: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const getStatesForBrand = () => {
  return dispatch => {
    axios.post(`${API_URL}get-states`, {
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.STATES_BY_REGIONS_ID, statesByRegionID: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}
export const getRegionsForBrand = () => {
  return dispatch => {
    axios.post(`${API_URL}get-regions`, {
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.REGIONS_BY_COUNTRY_ID, regionsByCountryID: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}
export const getCountries = () => {
  return dispatch => {
    axios.get(`${API_URL}get-countries`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.COUNTRIES, countries: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const getRegionsByCountryId = ({ id }) => {
  return dispatch => {
    axios.post(`${API_URL}get-regions`, {
      country_id: id
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.REGIONS_BY_COUNTRY_ID, regionsByCountryID: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const getStatesByRegionId = ({ id }) => {
  return dispatch => {
    axios.post(`${API_URL}get-states`, {
      region_id: id
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.STATES_BY_REGIONS_ID, statesByRegionID: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const getCitiesByStateId = ({ statesIds }) => {
  return dispatch => {
    axios.post(`${API_URL}cities`, {
      state_id: statesIds
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.CITIES_BY_STATES_ID, citiesByStateID: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const getHearAboutUsOptions = () => {
  return dispatch => {
    axios.get(`${API_URL}social-media-list`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.HEAR_ABOUT_OPTIONS, hearAboutOptions: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const getPrimarilyGoals = () => {
  return dispatch => {
    axios.get(`${API_URL}get-goals-attr`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.RD_PRIMARILY_GOALS, response: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

// Zubair
export const signUpRD = (body) => {
  const formData = new FormData()
  formData.append("email", body.email)
  formData.append("password", body.password)
  formData.append("confirm_password", body.confirm_password)
  formData.append("name", body.name)
  formData.append("is_rd", body.is_rd)
  formData.append("is_dietetic_intern", body.is_dietetic_intern)
  formData.append("is_other_rd", body.is_other_rd)
  formData.append("website", body.website)
  formData.append("cdr_number", body.cdr_number)
  formData.append("proof_of_certification", body.proof_of_certification)
  formData.append("gender", body.gender)
  formData.append("country_id", body.country_id)
  formData.append("region_id", body.region_id)
  formData.append("state_id", body.state_id)
  formData.append("city_id", body.city_id)
  formData.append("goals", body.goals)
  formData.append("primarily_interest", body.primarily_interest)
  formData.append("values", body.values)
  formData.append("special_diet", body.special_diet)
  formData.append("category", body.category)
  formData.append("here_about", body.here_about)
  formData.append("about_me", body.about_me)
  formData.append("personal_attributes", body.p_attributes)
  formData.append("industry", body.work_in)
  formData.append("speciality", body.speciality_in)
  formData.append("profile_pic", body.profile_pic)
  formData.append("is_other_hear_about", body.is_other_hear_about)

  formData.append("facebook_url[]", body.fb_links)
  formData.append("instagram_url[]", body.insta_links)
  formData.append("twitter_url[]", body.twitter_links)
  formData.append("linkedin_url[]", body.linkedIn_links)
  formData.append("pinterest_url[]", body.pinterest_links)
  formData.append("tiktok_url[]", body.tikTok_links)
  formData.append("followers", body.followers)
  formData.append("re_captcha_token", body.re_captcha_token)
  formData.append("timezone", body.time_zone)
  formData.append("location", body.user_location)
  formData.append("postal_code", body.postal_code)

  return dispatch => {
    axios.post(`${API_URL}rd-submit`, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      const userData = {
        name: res.data.name,
        email: res.data.email,
        role: res.data.role,
        phone_number: res.data.phone_number,
        ability: [{ action: "manage", subject: res.data.role }],
        profile_image: res.data.profile_img,
        profile_approved: res.data.profile_approved,
        is_rd_plan: res.data.is_rd_plan,
        selected_rd_plan: res.data.selected_rd_plan,
        is_tour: res.data.tour,
        email_verified: res.data.email_verified,
        stripe_account: res.data.stripe_account,
        stripe_account_status: res.data.stripe_account_status
      }
      if (res.data.success) {
        localStorage.setItem(`${process.env.REACT_APP_USERDATA}`, JSON.stringify(userData))
        localStorage.setItem(`${process.env.REACT_APP_USERTOKEN}`, res.data.token)
        dispatch({ type: AUTH.RD_SIGNUP, res })
      } else {
        dispatch({ type: AUTH.RD_SIGNUP, res })
      }
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const signUpRDFinal = (dataObj) => async (dispatch) => {
  dispatch({type: RD_SIGNUP_FINAL.REQUEST})
  const header = {
    Accept: "application/json"
  }
  try {
    const {data} = await axios.post(`${API_URL}rd-submit`, dataObj, {headers: header})
    const userData = {
      ...data,
      ability: [{action: "manage", subject: data.role}],
      profile_image: data.profile_img,
      is_tour: data.tour
    }
    localStorage.setItem(`${process.env.REACT_APP_USERDATA}`, JSON.stringify(userData))
    localStorage.setItem(`${process.env.REACT_APP_USERTOKEN}`, data.token)
    dispatch({type: RD_SIGNUP_FINAL.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_SIGNUP_FINAL.ERROR, payload: error})
  }
}

export const signUpRDFinalReset = () => async (dispatch) => {
  dispatch({ type: RD_SIGNUP_FINAL.RESET})
}

// Brands

export const getBrandCompanySelectValues = () => {
  return dispatch => {
    axios.get(`${API_URL}brand-product-attr`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: 'GET_BRAND_COMPANY_SELECT_VALUES', response: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const getBrandGoalsSelectValues = () => {
  return dispatch => {
    axios.get(`${API_URL}get-goals-attr`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: 'GET_BRAND_GOALS_SELECT_VALUES', response: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const verifyBrandPaymentCard = ({ card_number, name_on_card,
  expiry_month, expiry_year, cvc, email, re_captcha_token }) => {
  return dispatch => {
    axios.post(`${API_URL}validate-card-info`, {
      card_number,
      name_on_card,
      expiry_month,
      expiry_year,
      cvc,
      email,
      re_captcha_token
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      dispatch({ type: AUTH.BRANDS_CARD_VERIFICATION, brandCardVerificationRes: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const verifyCouponCode = ({ coupon_code }) => {
  return dispatch => {
    axios.post(`${API_URL}brand/verify-coupon`, {
      coupon_code
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${getToken()}`
      }
    }).then((res) => {
      dispatch({ type: AUTH.BRANDS_COUPON_CODE_VERIFICATION, brandCouponCodeVerificationRes: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

//Zubair
export const signUpBrand = (body) => {
  const formData = new FormData()
  formData.append("name", body.firstAndLastName)
  formData.append("company_name", body.companyName)
  formData.append("company_logo", body.company_logo)
  formData.append("phone_number", body.phoneNumber)
  formData.append("email", body.email)
  formData.append("password", body.password)
  formData.append("confirm_password", body.confirmPassword)

  formData.append("website", body.website)
  formData.append("facebook_link", body.facebook_link)
  formData.append("instagram_link", body.instagram_link)
  formData.append("twitter_url", body.twitter_url)
  formData.append("pinterest_url", body.pinterest_url)
  formData.append("linkedin_url", body.linkedin_url)
  formData.append("tiktok_url", body.tiktok_url)
  formData.append("goals", body.goals)
  formData.append("gender", body.gender)
  formData.append("country_id", body.country_id)
  formData.append("region_id", body.region_id)
  formData.append("person_title", body.person_title)

  formData.append("stripe_customer_id", body.stripe_customer_id)
  formData.append("is_other_hear_about", body.is_other_hear_about)

  if (body.state_id.length === 0) {
    formData.append("state_id[]", body.state_id)
  }

  body.state_id.forEach(id => {
    formData.append("state_id[]", id)
  })

  body.city_id.forEach(id => {
    formData.append("city_id[]", id)
  })

  if (body.city_id.length === 0) {
    formData.append("city_id[]", body.city_id)
  }

  if (body.personal_attributes.length === 0) {
    formData.append("personal_attributes[]", body.personal_attributes)
  }

  body.personal_attributes.forEach(id => {
    formData.append("personal_attributes[]", id)
  })

  if (body.industry.length === 0) {
    formData.append("industry[]", body.industry)
  }

  body.industry.forEach(id => {
    formData.append("industry[]", id)
  })

  if (body.speciality.length === 0) {
    formData.append("speciality[]", body.speciality)
  }

  body.speciality.forEach(id => {
    formData.append("speciality[]", id)
  })

  if (body.values.length === 0) {
    formData.append("values[]", body.values)
  }

  body.values.forEach(id => {
    formData.append("values[]", id)
  })

  if (body.special_diet.length === 0) {
    formData.append("special_diet[]", body.special_diet)
  }

  body.special_diet.forEach(id => {
    formData.append("special_diet[]", id)
  })

  if (body.category.length === 0) {
    formData.append("category[]", body.category)
  }

  body.category.forEach(id => {
    formData.append("category[]", id)
  })

  if (body.here_about.length === 0) {
    formData.append("here_about[]", body.here_about)
  }

  body.here_about.forEach(id => {
    formData.append("here_about[]", id)
  })

  return dispatch => {
    axios.post(`${API_URL}brand-submit`, formData, {
      headers: {
        Accept: "application/json",
        // "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => {
      const userData = {
        name: res.data.name,
        email: res.data.email,
        role: res.data.role,
        chat_id: res.data.chat_id,
        phone_number: res.data.phone_number,
        ability: [{ action: "manage", subject: res.data.role }],
        profile_image: res.data.profile_img,
        subscriptionexp: res.data.subscription_exp,
        parent: res.data.parent_id,
        is_tour: res.data.tour,
        email_verified: res.data.email_verified
      }
      if (res.data.success) {
        localStorage.setItem(`${process.env.REACT_APP_USERDATA}`, JSON.stringify(userData))
        localStorage.setItem(`${process.env.REACT_APP_USERTOKEN}`, res.data.token)
        dispatch({ type: AUTH.BRAND_SIGNUP, res })
      }
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

// Using
export const signUpBrandFinal = (dataObj) => async (dispatch) => {
  dispatch({type: BRAND_SIGNUP_FINAL.REQUEST})
  const header = {
    Accept: "application/json"
  }
  try {
    const {data} = await axios.post(`${API_URL}brand-submit`, dataObj, {headers: header})
    const userData = {
      ...data,
      ability: [{action: "manage", subject: data.role}],
      subscriptionexp: data.subscription_exp,
      parent: data.parent_id,
      is_tour: data.tour
    }
    localStorage.setItem(`${process.env.REACT_APP_USERDATA}`, JSON.stringify(userData))
    localStorage.setItem(`${process.env.REACT_APP_USERTOKEN}`, data.token)
    dispatch({type: BRAND_SIGNUP_FINAL.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: BRAND_SIGNUP_FINAL.ERROR, payload: error})
  }
}

// Using
export const signUpBrandFinalReset = () => async (dispatch) => {
  dispatch({type: BRAND_SIGNUP_FINAL.RESET})
}

export const getPurchasePlans = (type) => {
  return dispatch => {
    axios.get(`${API_URL}plan-list/${type}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: AUTH.PURCHASE_PLANS, purchasePlans: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.AUTH_ERROR, error })
    })
  }
}

export const activePlan = ({plan_id, coupon_code, re_captcha_token}) => {
  return dispatch => {
    axios.post(`${API_URL}brand/get-plan`, {plan_id, coupon_code, re_captcha_token}, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${getToken()}`
          }
        }
    ).then((res) => {
      dispatch({type: AUTH.ACTIVE_PLAN, activePlan: res.data})
    }).catch(error => {
      dispatch({type: GENERAL.AUTH_ERROR, error})
    })
  }
}

export const checkPlanStatus = (id) => {
  return dispatch => {
    axios.post(`${API_URL}brand/get-plan?check=true`, {plan_id: id}, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${getToken()}`
          }
        }
    ).then((res) => {
      dispatch({type: AUTH.PLAN_STATUS, planStatus: res.data})
    }).catch(error => {
      dispatch({type: GENERAL.AUTH_ERROR, error})
    })
  }
}

function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem('userDetails')
  let tokenDetails = ''
  if (!tokenDetailsString) {
    dispatch(logout(history))
    return
  }

  tokenDetails = JSON.parse(tokenDetailsString)
  const expireDate = new Date(tokenDetails.expireDate)
  const todaysDate = new Date()

  if (todaysDate > expireDate) {
    dispatch(logout(history))
    return
  }
  // dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime()
  runLogoutTimer(dispatch, timer, history)
}
export const nullResponse = (property) => {
  return dispatch => {
    switch (property) {
      case NULL.PLAN_STATUS:
        dispatch({ type: AUTH.PLAN_STATUS, planStatus: null })
        break
      case NULL.ACTIVE_PLAN:
        dispatch({ type: AUTH.ACTIVE_PLAN, activePlan: null })
        break
      case NULL.BRANDS_COUPON_CODE_VERIFICATION:
        dispatch({ type: AUTH.BRANDS_COUPON_CODE_VERIFICATION, brandCouponCodeVerificationRes: null })
        break
      case NULL.VERIFY_EMAIL:
        dispatch({ type: 'SIGNUP_DT_VERIFY_EMAIL', response: null })
        break
      case 'LOGIN':
        dispatch({ type: 'LOGIN', data: null })
        break
      case 'USER_RESET_PASSWORD':
        dispatch({ type: 'USER_RESET_PASSWORD', resetPasswordResponse: null })
        break
    }
  }
}

export const nullResponseAuth = (property) => {
  return dispatch => {
    switch (property) {
      case NULL.PLAN_STATUS:
        dispatch({ type: AUTH.PLAN_STATUS, planStatus: null })
        break
      case NULL.ACTIVE_PLAN:
        dispatch({ type: AUTH.ACTIVE_PLAN, activePlan: null })
        break
      case NULL.BRANDS_COUPON_CODE_VERIFICATION:
        dispatch({ type: AUTH.BRANDS_COUPON_CODE_VERIFICATION, brandCouponCodeVerificationRes: null })
        break
      case NULL.VERIFY_EMAIL:
        dispatch({ type: 'SIGNUP_DT_VERIFY_EMAIL', response: null })
        break
      case 'USER_RESET_PASSWORD':
        dispatch({ type: 'USER_RESET_PASSWORD', resetPasswordResponse: null })
        break
    }
  }
}

export const setTour = () => {
  axios.get(`${API_URL}tour`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  })
}

// export const getCityAndState = (zipCode = 90001) => {
//   axios.get(`/.netlify/functions/getCityState?&zipcode=${zipCode}`).then((res) => {
//     console.log(res)
//   })
// }
