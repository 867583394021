import {useState, useContext, Fragment, useEffect} from 'react'
import classnames from 'classnames'
import Avatar from '@components/avatar'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {toast, Slide} from 'react-toastify'
import {handleLogin} from '@store/actions/auth'
import {AbilityContext} from '@src/utility/context/Can'
import {Link, useHistory} from 'react-router-dom'
import InputPasswordToggle from '@components/input-password-toggle'
import {getHomeRouteForLoggedInUser, isObjEmpty} from '@utils'
import {Coffee} from 'react-feather'
import {Formik} from "formik"
import * as yup from "yup"
import {
    Row,
    Col,
    CardTitle,
    CardText,
    Form,
    Input,
    FormGroup,
    Label,
    Button,
    Spinner
} from 'reactstrap'
import logo from '@src/assets/images/logo.png'
import '@styles/base/pages/page-auth.scss'
import queryString from "query-string"
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ErrorToastContent = ({msg}) => (
    <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                <Avatar size='sm' color='error' icon={<Coffee size={12}/>}/>
                <h6 className='toast-title font-weight-bold'>Opps</h6>
            </div>
        </div>
        <div className='toastify-body'>
            <span className="text-danger"><strong>{msg}</strong></span>
        </div>
    </Fragment>
)

const Login = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [isLoading, setIsLoading] = useState(false)
    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)
    const [queryStatus, setQueryStatus] = useState('')
    const [target, setTarget] = useState('other')
    const {errors} = useForm()
    const ability = useContext(AbilityContext)
    const dispatch = useDispatch()
    const history = useHistory()
    const authStoreSlice = useSelector(store => store.auth)

    const sweetAlert = withReactContent(Swal)

    useEffect(() => {
        if (!!authStoreSlice.userData) {
            setIsLoading(false)
            setCaptchaError(false)
            setCaptchaValue(null)
            if (authStoreSlice.userData.success) {
                if (authStoreSlice.userData.role === 'brand') {
                    ability.update([{action: "manage", subject: authStoreSlice.userData.role}])
                    if (authStoreSlice.userData.subscription_exp) {
                        history.push('/subscription-plans')
                    } else {
                        const url = JSON.parse(localStorage.getItem('redirect_url'))
                        if (url?.role === 'brand') {
                            localStorage.removeItem('redirect_url')
                            window.location.href = url.path
                        } else {
                            history.push('/dashboard/brands')
                        }
                    }
                } else if (authStoreSlice.userData.role === 'rd') {
                    ability.update([{action: "manage", subject: authStoreSlice.userData.role}])
                    if (!authStoreSlice.userData.profile_approved) {
                        history.push('/pending-account-msg')
                    } else {
                        const url = JSON.parse(localStorage.getItem('redirect_url'))
                        if (url?.role === 'rd') {
                            localStorage.removeItem('redirect_url')
                            window.location.href = url.path
                        } else {
                            history.push('/dashboard/rd')
                        }
                    }
                } else {
                    ability.update([{action: "manage", subject: "all"}])
                    history.push(getHomeRouteForLoggedInUser(authStoreSlice.userData.role))
                }
            }

            if (authStoreSlice.userData.success === false) {
                toast.error(
                    <ErrorToastContent msg={authStoreSlice.userData.msg} name={authStoreSlice.userData.name || authStoreSlice.userData.email} role={authStoreSlice.userData.role}/>,
                    {transition: Slide, hideProgressBar: true, autoClose: 5000}
                )
            }
            dispatch({type: 'LOGIN', data: null})
        }
    }, [authStoreSlice])

    useEffect(() => {
        const parsed = queryString.parseUrl(window.location.href)
        setQueryStatus(parsed?.query?.session)
        setTarget(parsed?.query?.target || 'other')
        window.history.pushState('', '', '/login')
    }, [])

    const onSubmit = async (data) => {
        setIsLoading(true)
        const token = await executeRecaptcha('login')
        if (token) {
            dispatch(handleLogin({...data, target, re_captcha_token: token}))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    const handleFocus = (e) => {
        e.target.removeAttribute('readonly')
    }

    return (
        <div className='auth-wrapper auth-v2'>
            <Row className='auth-inner m-0'>
                <Link className='brand-logo' to='/'>
                    <img src={logo} width="140" alt='logo'/>
                </Link>
                <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' sm='12'>
                    <Col className='px-xl-2 mx-auto bg-light p-3 rounded' sm='8' md='6' lg='4'>
                        <CardTitle tag='h2' className='font-weight-bold mb-1'>
                            Welcome to The RD Link! 👋
                        </CardTitle>
                        {queryStatus && (
                            <CardText className='mb-2 bg-danger text-white p-1'>Your session has expired please login again.</CardText>
                        )}
                        <CardText className='mb-2'>Please sign-in to your account to pick up where you left off.</CardText>
                        <Formik
                            initialValues={{
                                email: "",
                                password: ""
                            }}
                            validationSchema={yup.object().shape({
                                email: yup.string().email().required("This field is required"),
                                password: yup.string().required("This field is required")
                            })}
                            onSubmit={(values, form) => {
                                onSubmit(values)
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  touched,
                                  values
                              }) => (
                                <Form className='auth-reset-password-form mt-2' onSubmit={handleSubmit}>
                                    <div className='form-group form-password-toggle'>
                                        <Label className={classnames({
                                            'is-invalid': touched.email && errors.email,
                                            'form-label': true
                                        })} htmlFor={`email`}>
                                            Email<span className="is-invalid">*</span>
                                        </Label>
                                        <Input
                                            type='email'
                                            name='email'
                                            placeholder='john@example.com'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            id={`email`}
                                            className={classnames({'is-invalid': touched.email && errors.email})}
                                            readOnly={true}
                                            onFocus={(e) => handleFocus(e)}
                                        />
                                        <div className="validation-err">{touched.email && errors.email}</div>
                                    </div>
                                    <FormGroup>
                                        <Label className={classnames({
                                            'is-invalid': touched.password && errors.password,
                                            'form-label': true
                                        })} htmlFor='password'>
                                            Password<span className="is-invalid">*</span>
                                        </Label>

                                        <InputPasswordToggle
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            name={`password`}
                                            id={`password`}
                                            className={classnames({'is-invalid': touched.password && errors.password})}
                                            readOnly={true}
                                            onFocus={(e) => handleFocus(e)}
                                        />
                                        <div className="validation-err">{touched.password && errors.password}</div>
                                    </FormGroup>
                                    <Button.Ripple type='submit' disabled={isLoading} color='primary' block>
                                        {isLoading ? (<Spinner style={{width: '20px', height: '20px'}}/>) : 'Sign in'}
                                    </Button.Ripple>
                                </Form>)}
                        </Formik>
                        <p className='text-center mt-2'>
                            <Link to='/forgot-password'>
                                <span>Forgot Password?</span>
                            </Link>
                        </p>
                        <p className='text-center mt-2'>
                            <span className='mr-25'>New on our platform?</span>
                            <Link to='/createloginrd'>
                                <span>Create an account</span>
                            </Link>
                        </p>
                    </Col>
                </Col>
            </Row>
        </div>
    )
}

export default Login
