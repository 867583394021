import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Card, CardBody, Form, FormGroup, Label, Input, CustomInput, Button, Spinner} from 'reactstrap'
import Logo from '../../../assets/images/logo.png'
import '@styles/base/pages/page-auth.scss'
import classnames from "classnames"
import {ArrowRight, FileText, Info, Layout, MapPin, User, Users} from "react-feather"
import {Formik} from "formik"
import * as yup from "yup"
import {useSelector, useDispatch} from "react-redux"
import {
    signUpBrandFinal,
    signUpBrandFinalReset
} from '@store/actions/auth'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useHistory} from 'react-router'
import InputPasswordToggle from '@components/input-password-toggle'
import {AbilityContext} from '@src/utility/context/Can'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"

const BrandSignup = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()

    const urlRegex = /([hH][tT]{2}[pP][sS]?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

    const onlyCharRegex = /^[aA-zZ\s]+$/

    const PROFILE_PIC_FORMATS = ["image/png", "image/jpeg", "image/jpg"]

    const PROFILE_PIC_MAX_SIZE = 2 * 1024 * 1024

    const ability = useContext(AbilityContext)

    const sweetAlert = withReactContent(Swal)

    const history = useHistory()

    const dispatch = useDispatch()

    const {
        brandSignupFinalLoading,
        brandSignupFinalData,
        brandSignupFinalError
    } = useSelector(state => state.auth)

    useEffect(() => {
        if (brandSignupFinalError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (brandSignupFinalError?.response?.data?.email) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandSignupFinalError?.response?.data?.email[0]}</li>`)
            }
            if (brandSignupFinalError?.response?.data?.password) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandSignupFinalError?.response?.data?.password[0]}</li>`)
            }
            if (brandSignupFinalError?.response?.data?.confirm_password) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandSignupFinalError?.response?.data?.confirm_password[0]}</li>`)
            }
            if (brandSignupFinalError?.response?.data?.name) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandSignupFinalError?.response?.data?.name[0]}</li>`)
            }
            if (brandSignupFinalError?.response?.data?.company_name) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandSignupFinalError?.response?.data?.company_name[0]}</li>`)
            }
            if (brandSignupFinalError?.response?.data?.company_logo) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandSignupFinalError?.response?.data?.company_logo[0]}</li>`)
            }
            if (brandSignupFinalError?.response?.data?.goals) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandSignupFinalError?.response?.data?.goals[0]}</li>`)
            }
            if (brandSignupFinalError?.response?.data?.re_captcha_token) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandSignupFinalError?.response?.data?.re_captcha_token[0]}</li>`)
            }
            if (brandSignupFinalError?.response?.data?.message) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandSignupFinalError?.response?.data?.message}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(signUpBrandFinalReset())
        }
        if (brandSignupFinalData?.success) {
            ability.update([{action: "manage", subject: "brand"}])
            sweetAlert.fire('Congratulations!', 'Your profile has been created successfully.', 'success')
            dispatch(signUpBrandFinalReset())
            history.push('/subscription-plans')
            //history.push('/purchase-plan')
        }
    }, [brandSignupFinalError, brandSignupFinalData])

    const registerBrand = async (data) => {
        const token = await executeRecaptcha('brand_signup')
        if (token) {
            const formData = new FormData()
            formData.append("email", data.email)
            formData.append("password", data.password)
            formData.append("confirm_password", data.confirmPassword)
            formData.append("name", data.firstLastName)
            formData.append("company_name", data.companyName)
            formData.append("phone_number", data.phoneNo)
            formData.append("person_title", data.yourTitle)
            formData.append("website", data.companyWebsite)
            formData.append("company_logo", data.companyLogo)
            formData.append("goals", data.goals)
            formData.append("re_captcha_token", token)
            dispatch(signUpBrandFinal(formData))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    return (
        <div className='auth-wrapper auth-v2' id='rd-signup-form-final'>
            <Row className='auth-inner m-0'>
                <Col md={12} className="d-flex align-items-center justify-content-between mt-1">
                    <Link to='/'><img src={Logo} alt='logo' width="150"/></Link>
                    <h2 className='font-medium-4 font-weight-bold'><Users size='20'/> Create Brand Account</h2>
                    <Link to='/login'><h2 className='font-medium-1 text-primary text-underline'>Already have an account?</h2></Link>
                </Col>
                <Col className='d-flex align-items-center auth-bg pt-2 mt-1' lg='12' sm='12'>
                    <div className="w-75 mx-auto">
                        <Card>
                            <CardBody>
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                        confirmPassword: '',
                                        firstLastName: '',
                                        companyName: '',
                                        phoneNo: '',
                                        yourTitle: '',
                                        companyWebsite: '',
                                        companyLogo: '',
                                        goals: '',
                                        termsAndConditions: ''
                                    }}
                                    validationSchema={yup.object().shape({
                                        email: yup.string().email("Email format is invalid.").required("This field is required"),
                                        password: yup.string().min(8).max(15).matches(passwordRegex, "Should be 8 characters, Should contain Number and Special Character").required("This field is required"),
                                        confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match.").required("This field is required"),
                                        firstLastName: yup.string().trim().required("This field is required").matches(onlyCharRegex, "Only alphabets are allowed."),
                                        companyName: yup.string().trim().required("This field is required").matches(onlyCharRegex, "Only alphabets are allowed."),
                                        phoneNo: yup.string().required("This field is required").matches(/^[0-9]{10}$/, 'Must be exactly 10 digits'),
                                        yourTitle: yup.string().trim().required("This field is required").matches(onlyCharRegex, "Only alphabets are allowed."),
                                        companyWebsite: yup.string().when('companyWebsite', {
                                            is: (web) => web?.length > 0,
                                            then: yup.string().matches(urlRegex, "Invalid URL")
                                        }),
                                        companyLogo: yup.mixed().test(
                                            "fileFormat",
                                            "Logo is empty or invalid. Only allowed .PNG, .JPG, JPEG",
                                            value => (value && PROFILE_PIC_FORMATS.includes(value.type))
                                        ).test(
                                            "fileSize",
                                            "Logo size too large. Max 2MB.",
                                            value => (value && value.size <= PROFILE_PIC_MAX_SIZE)
                                        ),
                                        goals: yup.string().trim().required("This field is required"),
                                        termsAndConditions: yup.string().required("Terms & Conditions should be accepted.")
                                    }, ['companyWebsite', 'companyWebsite'])}
                                    onSubmit={(values) => {
                                        registerBrand(values)
                                    }}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                          setFieldValue
                                      }) => (
                                        <Form onSubmit={handleSubmit} autoComplete="off">
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <h4 className="font-italic text-primary">
                                                        <FileText size={18}/> Create Account
                                                    </h4>
                                                    <hr/>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='email'
                                                           className={classnames({'is-invalid': touched.email && errors.email, 'form-label': true})}>
                                                        Email <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Input type='email'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.email}
                                                           name='email'
                                                           id='email'
                                                           placeholder='Email'
                                                           className={classnames({'is-invalid': touched.email && errors.email})}
                                                           autoComplete="off"
                                                           disabled={brandSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.email && errors.email}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='password'
                                                           className={classnames({'is-invalid': touched.password && errors.password, 'form-label': true})}>
                                                        Password <span className="is-invalid">*</span>
                                                    </Label>
                                                    <InputPasswordToggle onBlur={handleBlur} onChange={handleChange}
                                                                         value={values.password}
                                                                         name='password'
                                                                         id='password'
                                                                         placeholder='Password'
                                                                         className={classnames({'is-invalid': touched.password && errors.password})}
                                                                         disabled={brandSignupFinalLoading}
                                                    />
                                                    <div className="validation-err text-capitalize">{touched.password && errors.password}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='confirmPassword'
                                                           className={classnames({'is-invalid': touched.confirmPassword && errors.confirmPassword, 'form-label': true})}>
                                                        Confirm Password <span className="is-invalid">*</span>
                                                    </Label>
                                                    <InputPasswordToggle onBlur={handleBlur}
                                                                         onChange={handleChange}
                                                                         value={values.confirmPassword}
                                                                         name='confirmPassword'
                                                                         id='confirmPassword'
                                                                         placeholder='Confirm Password'
                                                                         className={classnames({'is-invalid': touched.confirmPassword && errors.confirmPassword})}
                                                                         disabled={brandSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.confirmPassword && errors.confirmPassword}</div>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <h4 className="font-italic text-primary pt-2">
                                                        <User size={18}/> Create Profile
                                                    </h4>
                                                    <hr/>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='firstLastName'
                                                           className={classnames({'is-invalid': touched.firstLastName && errors.firstLastName, 'form-label': true})}>
                                                        First and Last Name <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Input name='firstLastName'
                                                           id='firstLastName'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.firstLastName}
                                                           placeholder='First and Last Name'
                                                           className={classnames({'is-invalid': touched.firstLastName && errors.firstLastName})}
                                                           disabled={brandSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.firstLastName && errors.firstLastName}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='companyName'
                                                           className={classnames({'is-invalid': touched.companyName && errors.companyName, 'form-label': true})}>
                                                        Company Name <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Input name='companyName'
                                                           id='companyName'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.companyName}
                                                           placeholder='Company Name'
                                                           className={classnames({'is-invalid': touched.companyName && errors.companyName})}
                                                           disabled={brandSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.companyName && errors.companyName}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='phoneNo'
                                                           className={classnames({'is-invalid': touched.phoneNo && errors.phoneNo, 'form-label': true})}>
                                                        Phone Number <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Input type="number"
                                                           name='phoneNo'
                                                           id='phoneNo'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.phoneNo}
                                                           placeholder='Phone Number'
                                                           className={classnames({'is-invalid': touched.phoneNo && errors.phoneNo})}
                                                           disabled={brandSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.phoneNo && errors.phoneNo}</div>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <h4 className="font-italic text-primary pt-2">
                                                        <Info size={18}/> Company Info
                                                    </h4>
                                                    <hr/>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='yourTitle'
                                                           className={classnames({'is-invalid': touched.yourTitle && errors.yourTitle, 'form-label': true})}>
                                                        Your Title <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Input name='yourTitle'
                                                           id='yourTitle'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.yourTitle}
                                                           placeholder='CEO | Manager'
                                                           className={classnames({'is-invalid': touched.yourTitle && errors.yourTitle})}
                                                           disabled={brandSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.yourTitle && errors.yourTitle}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='companyWebsite'
                                                           className={classnames({'is-invalid': touched.companyWebsite && errors.companyWebsite, 'form-label': true})}>
                                                        Company Website
                                                    </Label>
                                                    <Input name='companyWebsite'
                                                           id='companyWebsite'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.companyWebsite}
                                                           placeholder='Website URL'
                                                           className={classnames({'is-invalid': touched.companyWebsite && errors.companyWebsite})}
                                                           disabled={brandSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.companyWebsite && errors.companyWebsite}</div>
                                                </FormGroup>
                                                <FormGroup tag={Col} md='4'>
                                                    <Label htmlFor='profilePicture'
                                                           className={classnames({
                                                               'is-invalid': touched.companyLogo && errors.companyLogo,
                                                               'form-label': true
                                                           })}
                                                    >
                                                        Company Logo <span className="is-invalid">*</span>
                                                    </Label>
                                                    <CustomInput accept=".png, .jpg, .jpeg"
                                                                 type="file"
                                                                 className="form-control-file"
                                                                 id="profilePicture"
                                                                 onBlur={handleBlur}
                                                                 onChange={(e) => setFieldValue('companyLogo', e.target.files[0])}
                                                                 className={classnames({
                                                                     'input-file-error': touched.companyLogo && errors.companyLogo,
                                                                     'form-control-file': true
                                                                 })}
                                                                 disabled={brandSignupFinalLoading}
                                                                 label="Choose file (PNG, JPG, JPEG - Max 2MB)"
                                                    />
                                                    <div className="d-flex justify-content-between">
                                                        <div className="validation-err">{touched.companyLogo && errors.companyLogo}</div>
                                                    </div>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <h4 className="font-italic text-primary pt-1">
                                                        <MapPin size={18}/> Goals
                                                    </h4>
                                                    <hr/>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <Label htmlFor='goals'
                                                           className={classnames({
                                                               'is-invalid': touched.goals && errors.goals,
                                                               'form-label': true
                                                           })}
                                                    >
                                                        What do you hope to get out of using The RD Link? <span className="is-invalid">*</span>
                                                    </Label>
                                                    <Input type='textarea'
                                                           onBlur={handleBlur}
                                                           onChange={handleChange}
                                                           value={values.goals}
                                                           name='goals'
                                                           id='goals'
                                                           placeholder='Write here...'
                                                           rows="5"
                                                           className={classnames({'is-invalid': touched.goals && errors.goals})}
                                                           disabled={brandSignupFinalLoading}
                                                    />
                                                    <div className="validation-err">{touched.goals && errors.goals}</div>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12'>
                                                    <div className="d-flex bg-light p-1">
                                                        <CustomInput id="termsAndConditions"
                                                                     type='checkbox'
                                                                     name='termsAndConditions'
                                                                     label='I agree to the'
                                                                     className={classnames({'checkbox-error': touched.termsAndConditions && errors.termsAndConditions})}
                                                                     onChange={(e) => {
                                                                         setFieldValue('termsAndConditions', e.target.checked ? 'Agreed' : '')
                                                                     }}
                                                                     disabled={brandSignupFinalLoading}
                                                        />
                                                        <a href='/terms-and-conditions' target="_blank" className="ml-1">Terms & Conditions</a>
                                                    </div>
                                                    <div className="validation-err ml-1">{touched.termsAndConditions && errors.termsAndConditions}</div>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup tag={Col} md='12' className="align-items-center d-flex justify-content-end">
                                                    <Button.Ripple type='submit' color='primary' className='btn btn-success' disabled={brandSignupFinalLoading}>
                                                        {brandSignupFinalLoading ? (
                                                            <span>
                                                                <Spinner style={{width: '16px', height: '16px'}}/> Creating Account...
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                Create Account <ArrowRight size="18"/>
                                                            </span>
                                                        )}
                                                    </Button.Ripple>
                                                </FormGroup>
                                            </Row>
                                        </Form>)}
                                </Formik>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default BrandSignup
